import moment from "moment";

const dayDifferenceHelper = ({ startTime, endTime, startDate, endDate }) => {
  console.log(
    "First  :dayDifferenceHelper day dif",
    startDate,
    endDate,
    startTime,
    endTime
  );
  let momentStartDate, momentEndDate, dayDifference;
  momentStartDate = moment(startDate);
  momentEndDate = moment(endDate);
  dayDifference = momentEndDate.diff(momentStartDate, "days") + 1;

  console.log("dayDifference dif", dayDifference);

  if (dayDifference == 1) {
    dayDifference = Number(24 - startTime) + Number(endTime) <= 24 ? 1 : 2;
  } else {
    let daysDiff = Number(24 - startTime) + Number(endTime) <= 24 ? 0 : 1;
    dayDifference += Number(daysDiff);
  }
  console.log(
    "Last  :dayDifferenceHelper day dif",
    dayDifference,
    startDate,
    endDate,
    startTime,
    endTime
  );

  return dayDifference;
};

export default dayDifferenceHelper;

export async function utcDateFormat(date) {
  return moment.utc(date).format("YYYY-MM-DD");
}

export async function dateFormat(date) {
  return moment(date).format("YYYY-MM-DD");
}
