import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import { url } from "../../../config";
import { COMMON_COLOR, COMMON_TEXT_COLOR } from "../../../constants/index";

class CompletedReservationGuest extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      reservationId: PropTypes.number.isRequired,
      hostName: PropTypes.string.isRequired,
      hostLastName: PropTypes.string.isRequired,
      hostProfilePic: PropTypes.string.isRequired,
      siteName: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "10px",
      textAlign: "center",
    };

    const buttonStyle = {
      margin: 0,
      fontFamily: "Arial",
      padding: "10px 16px",
      textDecoration: "none",
      borderRadius: "2px",
      border: "1px solid",
      textAlign: "center",
      verticalAlign: "middle",
      fontWeight: "bold",
      fontSize: "18px",
      whiteSpace: "nowrap",
      background: "#ffffff",
      borderColor: COMMON_COLOR,
      backgroundColor: COMMON_COLOR,
      color: "#ffffff",
      borderTopWidth: "1px",
    };

    const bookingTitle = {
      paddingBottom: "20px",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "25px",
      margin: "0",
      padding: "0",
      textAlign: "center",
    };

    const profilePic = {
      borderRadius: "999px",
      margin: "0",
      padding: "0",
      lineHeight: "150%",
      borderSpacing: "0",
      width: "125px",
    };

    const userName = {
      color: "#565a5c",
      fontSize: "26px",
      fontWeight: "bold",
      paddingBottom: "5px",
    };

    const subTitle = {
      color: "#565a5c",
      fontSize: "18px",
      fontWeight: "bold",
      paddingBottom: "5px",
    };

    const {
      content: { reservationId, logo, siteName },
    } = this.props;
    const {
      content: { hostName, hostLastName, hostProfilePic },
    } = this.props;
    let messageURL = url + "/review/write/" + reservationId;
    let imageURL;
    if (hostProfilePic) {
      imageURL = url + "/images/avatar/medium_" + hostProfilePic;
    }

    return (
      <Layout>
        <Header
          color="rgb(255, 90, 95)"
          backgroundColor="#F7F7F7"
          logo={logo}
          siteName={siteName}
        />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>
                    {hostProfilePic && (
                      <img style={profilePic} src={imageURL} height={125} />
                    )}
                  </div>
                  <EmptySpace height={20} />
                  <h1 style={bookingTitle}>
                    We hope you had an enjoyable trip with {hostName}!
                  </h1>
                  <EmptySpace height={20} />
                  <div>
                    As you have just ended your rental experience, now is the
                    perfect time to share your thoughts. Please take a moment to
                    tell {hostName} what you loved and what they can do better.
                    Your feedback is an essential part of our community, helping
                    us to improve and provide excellent service.
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    Writing a review only takes a few minutes, and your insights
                    are greatly appreciated. To leave your feedback, please
                    click on the button below.
                  </div>
                  <EmptySpace height={50} />
                  <div>
                    <a href={messageURL} style={buttonStyle}>
                      Write a Review
                    </a>
                  </div>
                  <EmptySpace height={40} />
                  <div>
                    We look forward to hosting your next car rental adventure on
                    Arah Rides. Thank you for being a valued member of our
                    community! <br></br>
                    Best regards, The Arah Rides Team
                  </div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={50} />
        </div>
        <Footer siteName={siteName} />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default CompletedReservationGuest;
