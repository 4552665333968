import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, TBody, TR, TD } from "oy-vey";
import Layout from "../layouts/Layout";
import Header from "../modules/Header";
import Body from "../modules/Body";
import Footer from "../modules/Footer";
import EmptySpace from "../modules/EmptySpace";
import { url } from "../../../config";
import {
  COMMON_COLOR,
  COMMON_TEXT_COLOR,
  TOGGLE_ON_COLOR,
} from "../../../constants/index";

class BookingConfirmationGuest extends Component {
  static propTypes = {
    content: PropTypes.shape({
      hostName: PropTypes.string.isRequired,
      guestName: PropTypes.string.isRequired,
      listTitle: PropTypes.string.isRequired,
      listCity: PropTypes.string.isRequired,
      threadId: PropTypes.number.isRequired,
      siteName: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    const textStyle = {
      color: COMMON_TEXT_COLOR,
      backgroundColor: "#F7F7F7",
      fontFamily: "Arial",
      fontSize: "16px",
      padding: "35px",
    };

    const linkText = {
      color: "#1852D8",
      fontSize: "16px",
      textDecoration: "none",
      cursor: "pointer",
    };

    const {
      content: {
        guestName,
        hostName,
        listTitle,
        listCity,
        threadId,
        reservationId,
        logo,
        siteName,
      },
    } = this.props;
    let contactURL = url + "/message/" + threadId + "/renter";
    const receiptURL = url + "/users/trips/receipt/" + reservationId;

    return (
      <Layout>
        <Header
          color="rgb(255, 90, 95)"
          backgroundColor="#F7F7F7"
          logo={logo}
          siteName={siteName}
        />
        <div>
          <Table width="100%">
            <TBody>
              <TR>
                <TD style={textStyle}>
                  <EmptySpace height={20} />
                  <div>Hi {guestName},</div>
                  <EmptySpace height={20} />
                  <div>
                    Get ready - Arah Rides is ready to take you on your
                    adventure.
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    Your booking request has been confirmed by {hostName}.
                    Please review the details of your trip and{" "}
                    <a style={linkText} href={contactURL}>
                      contact the owner
                    </a>{" "}
                    to coordinate the trip timings and key exchange.
                  </div>
                  <EmptySpace height={20} />
                  <div>
                    <a href={receiptURL} style={linkText}>
                      View receipt
                    </a>
                  </div>
                  <EmptySpace height={20} />
                  <div>Thank you for choosing {siteName}.</div>
                </TD>
              </TR>
            </TBody>
          </Table>
          <EmptySpace height={40} />
        </div>
        <Footer siteName={siteName} />
        <EmptySpace height={20} />
      </Layout>
    );
  }
}

export default BookingConfirmationGuest;
